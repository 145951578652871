if (document.querySelectorAll('[data-component="dropdown"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
        
        let parent = $('.dropdown');
        let dropdownMenu = $('.dropdown-menu');

        parent.on('hover', function () {
            $(this).find(dropdownMenu).stop(true, true).delay(200).fadeIn(500);
        }, function () {
            $(this).find(dropdownMenu).stop(true, true).delay(200).fadeOut(500);
        })
    })
}