if (document.querySelectorAll('[data-component="hamburger"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelector('[data-toggle="hamburger"]');
        const target = document.querySelector('.hamburger-menu');
        
        toggle.addEventListener('click', function () {
            this.classList.toggle('show');
            target.classList.toggle('show');
        })
    })
}