if (document.querySelectorAll('[data-component="scroll"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelectorAll('[data-toggle="scroll"]');

        function smoothScroll(e) {
            e.preventDefault();

            let toggleTarget = this.getAttribute("data-target");

            let offsetTop = document.querySelector(toggleTarget).offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
        
        toggle.forEach(function (e) {
            e.addEventListener('click', smoothScroll)
        })
    })
}