if (document.querySelectorAll('[data-component="sticky"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const navbarBack = document.querySelector('.navbar-back');
        const navbarFront = document.querySelector('.navbar-front');

        const addStickyNav = () => {
            document.querySelector('.navbar').classList.add('navbar--fixed');
        }
        
        const removeStickyNav = () => {
            document.querySelector('.navbar').classList.remove('navbar--fixed');
        }
        
        document.addEventListener('scroll', function () {
            let onScroll = window.pageYOffset;
            
            onScroll > 0 ? addStickyNav() : removeStickyNav()
        })

    })
}