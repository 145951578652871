if (document.querySelectorAll('[data-component="tab"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const keys = {
            left: 'ArrowLeft',
            right: 'ArrowRight'
        };
        const direction = {
            ArrowLeft: -1,
            ArrowRight: 1,
        }
    
        function attachEvents(tabs) {
            tabs.forEach(function (tab, index) {
                tab.addEventListener('keyup', function (e) {
                    if (e.code === keys.left || e.code === keys.right) {
                        switchTabOnArrowPress(e, tabs);
                    }
                })
    
                tab.addEventListener('click', function (e) {
                    e.preventDefault();
                    setActiveTab(tab, tabs);
                })
    
                tab.addEventListener('focus', function (e) {
                    setActiveTab(tab, tabs);
                })
    
                tab.index = index
            });
        }
    
        function switchTabOnArrowPress(e, tabs) {
            let pressed = e.code;
    
            if (direction[pressed]) {
                let target = e.target;
    
                if (target.index !== undefined) {
                    if (tabs[target.index + direction[pressed]]) {
                        tabs[target.index + direction[pressed]].focus();
                    } else if (pressed === keys.left) {
                        tabs[tabs.length - 1].focus();
                    } else if (pressed === keys.right) {
                        tabs[0].focus();
                    }
                }
            }
        }
    
        function setActiveTab(tab, tabs) {
            tabs.forEach(function (tabElement) {
                let tabContent = document.getElementById(tabElement.getAttribute('aria-controls'));
    
                if (tabElement === tab) {
                    tabElement.setAttribute('aria-selected', true);
                    
                    tabContent.removeAttribute('hidden');
                } else {
                    tabElement.setAttribute('aria-selected', false)
    
                    tabContent.setAttribute('hidden', true);
                }
            });
        }
    
        function initTabs(selector) {
            let tabContainers = [].slice.call(document.querySelectorAll(selector));
            
            tabContainers.forEach(function (tabContainer) {
                let tabs = [].slice.call(tabContainer.querySelectorAll('[aria-controls]'));
    
                attachEvents(tabs);
            });
        }
        
        initTabs('[role="tablist"]')
    });
}