if (document.querySelectorAll('[data-component="searchbox"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        function initSearchBox(element) {
            const searchButtons = element.querySelectorAll('[data-toggle="searchbox"]');
            
            searchButtons.forEach((searchButton) => {
                searchButton.addEventListener('click', toggleSearchBox)
            });

            function toggleSearchBox(e) {
                e.preventDefault();

                let navbar = e.target.closest('.navbar');

                if (navbar.classList.contains('searchbox-open')) {
                    closeSearchBox(navbar);
                } else {
                    closeAllSearchBoxes();
                    openSearchBox(navbar)
                }
            }

            function openSearchBox(navbar) {

                let searchInput = navbar.querySelector('.searchbox-dialog__input');
                let buttons = document.querySelectorAll('[data-toggle="searchbox"]');
                
                buttons.forEach((searchButton) => {
                    searchButton.setAttribute('aria-pressed', true)
                });

                navbar.classList.add('searchbox-open');
                searchInput.focus();

                document.addEventListener('keyup', keyPressHandler);
            }

            function closeSearchBox(navbar) {
                let buttons = navbar.querySelectorAll('[data-toggle="searchbox"]');

                buttons.forEach((searchButton) => {
                    searchButton.removeAttribute('aria-pressed')
                });

                navbar.classList.remove('searchbox-open');

                document.removeEventListener('keyup', keyPressHandler);
            }

            function closeAllSearchBoxes() {
                document.querySelectorAll('.navbar.searchbox-open').forEach((navbar) => {
                    closeSearchBox(navbar);
                });
            }

            function keyPressHandler(e) {
                if (e.key === 'Escape') {
                    closeSearchBox();
                }
            }
        }

        document.querySelectorAll('.navbar').forEach((navbar) => {
            initSearchBox(navbar);
        });
        
    })
}